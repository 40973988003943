<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'

import { useVOnboarding, VOnboardingStep, VOnboardingWrapper } from 'v-onboarding'
import 'v-onboarding/dist/style.css'
import AppMenuItem from './AppMenuItem.vue'
import { t } from '@/common/i18n'
import { Roles } from '@/models'
import { capitalizeString } from '@/utils/capitalize'
import { ApiService } from '@/services/ApiService'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '@/stores/auth'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import { useLayout } from '@/layout/composables/layout'

const authStore = useAuthStore()
const { onMenuToggle, onWindowResize, isCollapsed } = useLayout()
const { customerId, onBoarded, imageProfile } = storeToRefs(authStore)
const wrapper = ref(null)
const { start, finish } = useVOnboarding(wrapper)
const outsideClickListener = ref<((event: MouseEvent) => void) | null>(null)
const topbarMenuActive = ref(false)
const hasProfileImage = ref(false)
const pathImage = ref('')

const bindOutsideClickListener = () => {
  if (!outsideClickListener.value) {
    outsideClickListener.value = () => {
      topbarMenuActive.value = false
    }
    document.addEventListener('click', outsideClickListener.value)
  }
}

const endingOutBoarding = async () => {
  finish()
  await ApiService.updateEntity(RouteNamespace.customers, customerId.value, { onBoarded: true })
  useAuthStore().onBoarded = true
}
const steps = computed(() => [
  {
    attachTo: { element: '#welcome' },
    content: {
      title: t('outBoarding.welcome.title'),
      description: t('outBoarding.welcome.description')
    }
  },
  {
    attachTo: { element: '#location' },
    content: {
      title: t('outBoarding.locations.title'),
      description: t('outBoarding.locations.description')
    }
  },
  {
    attachTo: { element: '#customer' },
    content: {
      title: t('outBoarding.customers.title'),
      description: t('outBoarding.customers.description')
    }
  },
  {
    attachTo: { element: '#user' },
    content: {
      title: t('outBoarding.users.title'),
      description: t('outBoarding.users.description')
    }
  },
  {
    attachTo: { element: '#rfid-card' },
    content: {
      title: t('outBoarding.cards.title'),
      description: t('outBoarding.cards.description')
    }
  },
  {
    attachTo: { element: '#rate' },
    content: {
      title: t('outBoarding.rates.title'),
      description: t('outBoarding.rates.description')
    }
  },
  {
    attachTo: { element: '#payment' },
    content: {
      title: t('outBoarding.payments.title'),
      description: t('outBoarding.payments.description')
    }
  }
])
const menu = computed(() => [
  {
    label: t('sidebar.management'),
    icon: 'management',
    roles: [],
    items: [
      {
        label: t('sidebar.dashboard'),
        to: '/dashboard',
        icon: 'home',
        roles: [Roles.admin, Roles.superuser, Roles.user]
      },
      {
        label: t('sidebar.locations'),
        to: '/locations',
        icon: 'location',
        roles: [Roles.admin, Roles.superuser, Roles.user]
      },
      {
        label: t('sidebar.customers'),
        to: '/customers',
        icon: 'customer',
        roles: [Roles.admin]
      },
      {
        label: t('sidebar.users'),
        to: '/users',
        icon: 'user-face',
        roles: [Roles.admin, Roles.superuser]
      }
    ]
  },
  {
    label: t('sidebar.monetize'),
    icon: 'monetize',
    roles: [],
    items: [
      {
        label: t('sidebar.cards'),
        to: '/cards',
        icon: 'rfid-card',
        roles: [Roles.admin, Roles.superuser, Roles.user]
      },
      {
        label: t('sidebar.rates'),
        to: '/rates',
        icon: 'rate',
        roles: [Roles.admin, Roles.superuser]
      },
      {
        label: t('sidebar.payments'),
        to: '/payments',
        icon: 'payment',
        roles: [Roles.admin, Roles.superuser]
      }
    ]
  }
])
const preferencesMenu = computed(() => [
  {
    label: 'Ayuda',
    icon: 'help',
    to: '/help',
    roles: [Roles.admin, Roles.superuser, Roles.user],
    footerItem: true
  },
  {
    label: 'Configuraciones',
    icon: 'settings',
    to: '/settings',
    roles: [Roles.admin, Roles.superuser, Roles.user],
    footerItem: true
  },
  {
    label: t('detail.settings.actions.logout'),
    icon: 'logout',
    to: '/logout',
    roles: [Roles.admin, Roles.superuser, Roles.user],
    footerItem: true
  }
])

const handleImageError = () => {
  hasProfileImage.value = false
}

const checkViewportWidth = () => {
  onWindowResize(window.innerWidth < 1366)
}

onMounted(() => {
  if (!onBoarded.value) start()

  if (imageProfile.value !== '') {
    updateImage()
  }
  window.addEventListener('resize', checkViewportWidth)
  checkViewportWidth()
  bindOutsideClickListener()
})

onUnmounted(() => {
  window.removeEventListener('resize', checkViewportWidth)
})

const updateImage = () => {
  hasProfileImage.value = true
  pathImage.value = `http:${import.meta.env.VITE_REST_API}/public/profile/${imageProfile.value}`
}
</script>

<template>
  <VOnboardingWrapper ref="wrapper" :steps="steps" @exit="endingOutBoarding">
    <template #default="{ previous, next, step, isFirst, isLast, index }">
      <VOnboardingStep>
        <div class="flex flex-row onboarding-layout shadow sm:rounded-lg justify-content-between">
          <div class="px-4 py-5 sm:p-4">
            <div class="flex flex-row">
              <div v-if="step.content">
                <span v-if="step.content.title" class="text-lg font-bold leading-6 text-gray-900">
                  {{ step.content.title }}
                </span>
                <div
                  v-if="step.content.description"
                  class="mt-3 max-w-xl text-sm text-gray-500 text-justify"
                >
                  <p>{{ step.content.description }}</p>
                </div>
              </div>
              <div class="font-family-light text-sm">{{ `${index + 1}/${steps.length}` }}</div>
            </div>
            <div class="flex flex-row justify-content-between mt-3">
              <Button
                @click="previous"
                rounded
                class="button button-normal"
                size="small"
                :class="isFirst ? 'visible' : ''"
              >
                {{ t('outBoarding.actions.last') }}
              </Button>
              <Button
                v-if="!isLast"
                @click="next"
                rounded
                class="button button-onboarding"
                size="small"
              >
                {{ t('outBoarding.actions.next') }}
              </Button>
              <Button
                v-if="isLast"
                @click="endingOutBoarding"
                rounded
                class="button button-onboarding"
                size="small"
              >
                {{ t('outBoarding.actions.finish') }}
              </Button>
            </div>
            <div v-if="!isFirst" class="flex justify-content-end mt-3" @click="endingOutBoarding">
              <span class="cursor-pointer underline text-sm">{{
                t('outBoarding.actions.skip')
              }}</span>
            </div>
          </div>
        </div>
      </VOnboardingStep>
    </template>
  </VOnboardingWrapper>
  <aside class="layout-menu">
    <section>
      <div id="welcome" class="flex flex-row justify-content-between w-full">
        <div class="flex justify-content-center align-items-center" v-if="!hasProfileImage">
          <svg-icon name="chargevite" size="60" color="#EA2839" />
          <span class="font-bold text-4xl">{{ capitalizeString(t('brandName')) }}</span>
        </div>
        <div class="flex justify-content-center align-items-center pl-2" v-if="hasProfileImage">
          <Image :src="pathImage" alt="Image" :hidden="isCollapsed" @error="handleImageError" />
        </div>
      </div>
      <div class="mt-6">
        <ul>
          <template v-for="(section, sectionIndex) in menu" :key="sectionIndex">
            <AppMenuItem :item="section" :index="sectionIndex" :visible="!isCollapsed" />
          </template>
        </ul>
      </div>
    </section>
    <section>
      <ul>
        <template v-for="(section, sectionIndex) in preferencesMenu" :key="sectionIndex">
          <AppMenuItem :item="section" :index="sectionIndex" :visible="!isCollapsed" />
        </template>
      </ul>
      <button
        aria-label="Toggle sidebar"
        class="p-link layout-menu-button layout-topbar-button"
        :class="{ 'button-collapsed': isCollapsed }"
        @click="onMenuToggle()"
      >
        <i class="layout-menu-button__icon pi pi-angle-double-left"></i>
        <span class="layout-menu-button__text ml-2">{{ t('actions.collapse') }}</span>
      </button>
    </section>
  </aside>
</template>
<style scoped lang="scss">
.onboarding-layout {
  background-color: var(--borderGray);
  margin-left: 1rem;
  border-radius: 6px;
  z-index: 1;
}

.visible {
  visibility: hidden;
}
</style>
